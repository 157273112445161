import React from 'react';

import {
  GeoJSON,
  Tooltip,
  FeatureGroup,
} from 'react-leaflet';

const StatisticsLayer = ({
  featureType, features, featureValues,
  featureColors, selectedFeatureId,
  setSelectedFeatureId, featureValuesUnit,
}) => {

  return (
    <FeatureGroup onclick={(what) => { setSelectedFeatureId(parseInt(what.propagatedFrom.feature.properties.id)); }}>
      {
        features.map((feature) =>
          <GeoJSON key={featureType + feature.properties.id} data={feature}
            fillOpacity={0.8}
            color={selectedFeatureId == feature.properties.id ? '#ff00ff' : featureColors[feature.properties.id] || '#ffffff'}>
            <Tooltip>{featureValuesUnit === 'traversals' ? 'Pathway' : 'Area'} {feature.properties.id}: {featureValues.values[feature.properties.id] ? featureValues.values[feature.properties.id].toFixed(2) : ''} {featureValuesUnit}</Tooltip>
          </GeoJSON>
      )}
    </FeatureGroup>
  );

};

export default StatisticsLayer;
