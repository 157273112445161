import React from 'react';

export default function GradientLegend({ featureClasses, colorSchemeColors, featureValuesUnit }) {
  const rows = featureClasses.map((featureClass, ix) => (
    <tr key={(featureClass.lower.toFixed(2)).toString() + '-' + (featureClass.upper.toFixed(2)).toString()}>
      <td style={{ textAlign: 'right', paddingRight: '1em', whiteSpace: 'nowrap' }}>{(featureClass.lower.toFixed(2)).toString() + '-' + (featureClass.upper.toFixed(2)).toString()}</td>
      <td style={{ border: '1px solid #aaa', background: colorSchemeColors[ix], width: '3em' }}></td>
    </tr>
  ));

  return <table style={{ margin: '1em auto' }}><tbody>
    {rows}
  </tbody></table>;
}
