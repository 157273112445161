export const dayNames = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
export const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
export const hourIntervals = [
  '00-01',
  '01-02',
  '02-03',
  '03-04',
  '04-05',
  '05-06',
  '06-07',
  '07-08',
  '08-09',
  '09-10',
  '10-11',
  '11-12',
  '12-13',
  '13-14',
  '14-15',
  '15-16',
  '16-17',
  '17-18',
  '18-19',
  '19-20',
  '20-21',
  '21-22',
  '22-23',
  '23-00',
];

// convert filter values to string representation
export const mapValueToName = (field, value) => {
  if (value === 'all') return 'MEAN';

  if (field === 'month') {
    return monthNames[parseInt(value) - 1];
  } else if(field === 'dow') {
    return dayNames[parseInt(value) - 1];
  } else if (field === 'hour') {
    return hourIntervals[parseInt(value)];
  }

  return value;
};
