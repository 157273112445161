import React from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import DownloadIcon from '@material-ui/icons/GetApp';

import XLSX from 'xlsx';

import { mapValueToName } from './dataUtil.js';


const fieldsToNames = {
  'year': 'Year',
  'month': 'Month',
  'dow': 'Day of Week',
  'hour': 'Hour Interval',
  'age_class': 'Age Group',
  'sex': 'Sex',
};

function ExportButton ({
  featureData, filterParams,
  featureColumnName, valueColumnName,
  evolutionMode,
}) {
  const buttonRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickOpen = () => setAnchorEl(buttonRef.current);
  const handleClose = () => setAnchorEl(null);

  // a callback for exporting the currently available data
  const handleExport = React.useCallback((fileExt) => {
    if (!fileExt) fileExt = '.ods';

    const evolutionColumnName = evolutionMode === 'dow' ? 'Day of Week' : evolutionMode === 'hour' ? 'Hour Interval' : 'Month';
    const evolutionDimension = evolutionMode === 'dow' ? 'day' : evolutionMode;

    let headerRow = evolutionMode ?
      [featureColumnName, evolutionColumnName, valueColumnName + ' per ' + evolutionDimension] :
      [featureColumnName, valueColumnName]
    ;

    if (fileExt === '.csv') {
      // replace spaces with underscore for header names
      headerRow = headerRow.map((c) => c.split(' ').join('_').toLowerCase());
    }

    const exportData = [
      headerRow,
      ...featureData.filter((row) => row[evolutionMode] !== 'all').map((row) =>
        evolutionMode ? [row.area, mapValueToName(evolutionMode, row[evolutionMode]), row.value] : [row.area, row.value]
      )
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(exportData, { cellDates: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    let fileName = 'Park_visitor_activity';

    const filterEntries = Object.entries(filterParams).filter(([_, v]) => v);
    if (filterEntries.length) {
      // depending on the file format...
      if (fileExt === '.csv') {
        // put filters in name
        fileName = ['Park_visitor_activity', ...filterEntries.map(([field, value]) => 
          [field, ...value.split(',').map(mapValueToName.bind(this, field))].join('_'))
        ].join('__');
      } else {
        // keep the name simple, put filters in sheet
        const filterRows = filterEntries.map(([field, value]) =>
          [fieldsToNames[field], value.split(',').map(mapValueToName.bind(this, field)).join(', ')]
        );

        XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(filterRows), 'Filters');
      }
    }

    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, fileName + fileExt);
    handleClose();
  }, [
    featureData, filterParams,
    featureColumnName, valueColumnName,
    evolutionMode,
  ]);

  return (
    <React.Fragment>
      <Button ref={buttonRef}
        size="small" variant="text" color="primary"
        aria-label="export" aria-controls="export-menu" aria-haspopup="true"
        onClick={handleClickOpen}
        startIcon={<DownloadIcon />}
        disabled={featureData.length === 0}
      >
        Export
      </Button>

      <Menu
        id="export-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom' }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={() => handleExport('.ods')}>OpenDocument (.ods)</MenuItem>
        <MenuItem onClick={() => handleExport('.xlsx')}>Office Open XML (.xlsx)</MenuItem>
        <MenuItem onClick={() => handleExport('.csv')}>Comma-separated values (.csv)</MenuItem>
      </Menu>

    </React.Fragment>
  );
};

export default ExportButton;
