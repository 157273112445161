import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

function makeData(selectedFeatureValuesEvolution, sortBy) {
  if (selectedFeatureValuesEvolution) {
    let meanData = Object.keys(selectedFeatureValuesEvolution)
      .filter((k) => k === 'all')
      .map(key => ({ 'id': key, 'value': selectedFeatureValuesEvolution[key] }));
    let data = Object.keys(selectedFeatureValuesEvolution)
      .filter((k) => k !== 'all')
      .map(key => ({ 'id': key, 'value': selectedFeatureValuesEvolution[key] }));

    data.sort((a, b) => (parseFloat(a[sortBy]) > parseFloat(b[sortBy])) ? 1 : -1);
    return [...meanData, ...data];
  }
  else
    return [];
}

// https://nivo.rocks/bar/
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const SelectedFeatureValuesEvolutionBar = ({ selectedFeatureValuesEvolution, sortBy, keys, indexBy, selectedEvolutionValue, mapEvolutionValueToName, featureValuesUnit }) => (
  <ResponsiveBar
    data={makeData(selectedFeatureValuesEvolution, sortBy)}
    keys={keys}
    indexBy={indexBy}
    layout={'vertical'}
    margin={{ top: 20, right: 10, bottom: 40, left: 50 }}
    padding={0.3}
    colors={function getColor(datum) { return datum.indexValue === selectedEvolutionValue || parseInt(datum.indexValue) === selectedEvolutionValue ? '#ff00ff' : '#c83cb9'; }}
    defs={[]}
    fill={[]}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    borderWidth={0.1}
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -60,
      format: value => mapEvolutionValueToName(value),
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    }}
    tooltip={({ value, indexValue, color }) => (
      <span>
        {mapEvolutionValueToName(indexValue)}: {value.toFixed(2)} {featureValuesUnit}
      </span>
    )}
    enableLabel={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    legends={[]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);
