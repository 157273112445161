import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

// https://nivo.rocks/bar/
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsiveBar = ({ data, keys, indexBy, colors }) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={{ top: 16, right: 4, bottom: 40, left: 60 }}
    padding={0.3}
    colors={colors || { scheme: 'nivo' }}
    defs={[]}
    fill={[]}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -60,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      tickValues: 5,
    }}
    tooltip={({ value, indexValue, color }) => (
      <span>
        {indexValue}: <strong>{value}</strong>
      </span>
    )}
    enableLabel={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    legends={[]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);
