import React from 'react';
import { Route, Link } from 'react-router-dom';

import L from 'leaflet';
import axios from 'axios';
import clsx from 'clsx';

import {Helmet} from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from '@material-ui/icons/Tune';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ReplayIcon from '@material-ui/icons/Replay';
import InfoIcon from '@material-ui/icons/Info';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LaunchIcon from '@material-ui/icons/Launch';
import WalkIcon from '@material-ui/icons/DirectionsWalk';
import InputLabel from '@material-ui/core/InputLabel';
import { ResponsiveBar } from '@nivo/bar';

import {
  FormControl,
  FormControlLabel,
  Grid,
  Tooltip,
  Paper,
  Switch,
  Slider,
} from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { MyResponsiveBar } from './MyResponsiveBar';
import { FeatureValuesBar } from './vis/FeatureValuesBar';
import { FeatureClassesPie } from './vis/FeatureClassesPie';
import { FeatureValuesNav } from './vis/FeatureValuesNav';
import { SelectedFeatureValuesEvolutionBar } from './vis/SelectedFeatureValuesEvolutionBar';

import FilterToggleButtonGroup from './FilterToggleButtonGroup';
import MapLayout from './MapLayout';
import StatisticsLayer from './StatisticsLayer';
import SnapshotLayer from './SnapshotLayer';
import SimpleFeatureLayer from './SimpleFeatureLayer';
import GradientLegend from './GradientLegend';

import VisitorPositionsPanel from './VisitorPositionsPanel';
import ExportButton from './ExportButton';

import AboutDialog from './AboutDialog';
import WalkDataMaker from './WalkDataMaker';
import WhatAboutTheData from './WhatAboutTheData';


import {
  dayNames,
  monthNames,
  hourIntervals,
  mapValueToName,
} from './dataUtil.js';

import tinygradient from 'tinygradient';

// easy to change base api url
axios.defaults.baseURL = './api';

// use default deployment scenario to figure out the issues site path
const issuesPathname = window.location.pathname.replaceAll(/park-visitor-activity/g, 'park-issues');
const issuesUrl = window.location.origin + issuesPathname;

// figure out if we're in a simulation
const isSim = window.location.pathname.endsWith('-sim/');
const WalkData = isSim ? WalkDataMaker : WhatAboutTheData;

const divergingColorSchemeGradient = tinygradient([
  { color: '#fcf0ba', pos: 0 },
  { color: '#fa8067', pos: 0.5 },
  { color: '#a34a64', pos: 1 }
]);

const qualitativeColorSchemeColors = ['#3288bd', '#99d594', '#e6f598', '#fee08b', '#fc8d59', '#d53e4f'];

const leftDrawerWidth = 400;
const rightDrawerWidth = 400;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  appBar: {
    backgroundColor: 'rgb(245, 245, 245)',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarLeftShift: {
    width: `calc(100% - ${leftDrawerWidth}px)`,
    marginLeft: leftDrawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarRightShift: {
    width: `calc(100% - ${rightDrawerWidth}px)`,
    marginRight: rightDrawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarLeftRightShift: {
    width: `calc(100% - ${leftDrawerWidth}px - ${rightDrawerWidth}px)`,
    marginLeft: leftDrawerWidth,
    marginRight: rightDrawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  leftMenuButton: {
    marginRight: theme.spacing(2),
  },
  rightMenuButton: {
    marginLeft: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  leftDrawer: {
    width: leftDrawerWidth,
    flexShrink: 0,
  },
  rightDrawer: {
    width: rightDrawerWidth,
    flexShrink: 0,
  },
  leftDrawerPaper: {
    width: leftDrawerWidth,
  },
  rightDrawerPaper: {
    width: rightDrawerWidth,
  },
  leftDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  rightDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -leftDrawerWidth,
    marginRight: -rightDrawerWidth,
  },
  contentLeftShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  contentRightShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  map: {
    flex: 1,
    backgroundColor: 'rgb(221, 221, 221)',
  },
  toggleContainer: {
    margin: theme.spacing(1, 0),
    textAlign: 'center',
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%'
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  dataSelect: {
    fontSize: '1.25rem',
  },
  grow: {
    flexGrow: 1,
  },
  buttons: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  tooltip: {
    fontSize: '1em',
  },
}));

const evolutionSliderMarks = {
  "month": monthNames.map((value, index) => ({ value: index + 1, label: value, text: value })),
  "dow": dayNames.map((value, index) => ({ value: index + 1, label: value, text: value })),
  "hour": [...Array(24).keys()].map((index) => ({ value: index, label: `${index}-${(index + 1) % 24}`, text: `${index}-${(index + 1) % 24}` })),
}

function makeFeatureValues(pathData) {
  if (!pathData || !pathData.length) {
    return { 'min': 0, 'max': 1, 'values': {} };
  }

  let values = {};
  let minValue = Number.MAX_VALUE;
  let maxValue = Number.MIN_VALUE;
  for (const pathDatum of pathData) {
    values[pathDatum['area']] = pathDatum['value'];
    if (minValue > pathDatum['value'])
      minValue = pathDatum['value'];
    if (maxValue < pathDatum['value'])
      maxValue = pathDatum['value'];
  }

  return { 'min': minValue, 'max': maxValue, 'values': values };
}

function makeSelectedFeatureValuesEvolution(selectedFeatureData, evolutionMode) {
  let values = {};
  for (const datum of selectedFeatureData) {
    values[datum[evolutionMode]] = datum['value'];
  }
  return values;
}

function makeDivergingColorSchemeColors(featureClassesCount) {
  let colors = [];
  for (let i = 0; i < featureClassesCount; i++) {
    colors[i] = divergingColorSchemeGradient.rgbAt(i / (featureClassesCount - 1));
  }
  return colors;
}

function makeFeatureColors(featureClasses, colorSchemeColors) {
  let featureColors = {};
  for (let i = 0; i < featureClasses.length; i++) {
    for (let j = 0; j < featureClasses[i].values.length; j++) {
      featureColors[featureClasses[i].values[j].id] = colorSchemeColors[i];
    }
  }
  return featureColors;
}

function makeFeatureClassesColors(featureClasses, colorSchemeColors) {
  let featureClassesColors = {};
  for (let i = 0; i < featureClasses.length; i++) {
    featureClassesColors[featureClasses[i].id] = colorSchemeColors[i];
  }
  return featureClassesColors;
}

function makeFeatureClassesRangesEqualInterval(sortedFeatureData, featureClassesCount) {
  if (sortedFeatureData && sortedFeatureData.length) {
    let min = sortedFeatureData[0].value;
    let max = sortedFeatureData[sortedFeatureData.length - 1].value;
    let featureClassesRanges = [];
    const rangeWidth = (max - min) / featureClassesCount;
    for (let ix = 0; ix < featureClassesCount; ix++) {
      featureClassesRanges[ix] = { 'id': ix + 1, 'lower': min + ix * rangeWidth, 'upper': min + (ix + 1) * rangeWidth };
    }
    featureClassesRanges[featureClassesCount - 1].upper = max;
    return featureClassesRanges;
  }
  else
    return [];
}

function makeFeatureClassesRangesEqualCount(sortedFeatureData, featureClassesCount) {
  if (sortedFeatureData && sortedFeatureData.length) {
    let min = sortedFeatureData[0].value;
    let max = sortedFeatureData[sortedFeatureData.length - 1].value;
    const values = sortedFeatureData;
    let featureClassesRanges = [];
    let valuesIx = 0;
    let classesIx = 0;
    featureClassesRanges[classesIx] = { 'id': classesIx + 1, 'lower': min }
    for (; valuesIx < values.length; valuesIx++) {
      if ((valuesIx / values.length > (classesIx + 1) / featureClassesCount) && (values[valuesIx].value > values[valuesIx - 1].value)) {
        featureClassesRanges[classesIx].upper = values[valuesIx].value;
        classesIx++;
        featureClassesRanges[classesIx] = { 'id': classesIx + 1, 'lower': values[valuesIx].value };
        if (classesIx === featureClassesCount - 1)
          break;
      }
    }
    featureClassesRanges[classesIx].upper = max;
    return featureClassesRanges;
  }
  else
    return [];
}

function makeFeatureClasses(featureValues, featureClassesRanges) {
  let values = Object.keys(featureValues.values).map(key => ({ 'id': key, 'value': featureValues.values[key] })).sort((a, b) => (parseFloat(a.value) > parseFloat(b.value)) ? 1 : -1);
  let valuesIx = 0;
  let featureClasses = [];
  let classesIx = 0;
  for (; classesIx < featureClassesRanges.length; classesIx++) {
    const lower = featureClassesRanges[classesIx].lower;
    const upper = featureClassesRanges[classesIx].upper;
    featureClasses[classesIx] = { 'id': classesIx + 1, 'lower': lower, 'upper': upper, 'values': [] };
    for (; valuesIx < values.length; valuesIx++) {
      if (values[valuesIx].value < upper)
        featureClasses[classesIx].values.push(values[valuesIx]);
      else
        break;
    }
    if (classesIx === featureClassesRanges.length - 1)
      break;
  }
  for (; valuesIx < values.length; valuesIx++) {
    featureClasses[classesIx].values.push(values[valuesIx]);
  }
  return featureClasses;
}

function makeFeatureClassesEqualInterval(featureValues, featureClassesCount) {
  let min = featureValues.min;
  let max = featureValues.max;
  let values = Object.keys(featureValues.values).map(key => ({ 'id': key, 'value': featureValues.values[key] })).sort((a, b) => (parseInt(a.value) > parseInt(b.value)) ? 1 : -1);
  const valuesCount = values.length;
  let valuesIx = 0;
  let featureClasses = [];
  const classWidth = (max - min) / featureClassesCount;
  let classesIx = 0;
  featureClasses[classesIx] = { 'id': classesIx + 1, 'lower': min, 'values': [] }
  for (; valuesIx < valuesCount; valuesIx++) {
    if (values[valuesIx].value >= min + (classesIx + 1) * classWidth) {
      featureClasses[classesIx].upper = min + (classesIx + 1) * classWidth;
      classesIx++;
      featureClasses[classesIx] = { 'id': classesIx + 1, 'lower': min + classesIx * classWidth, 'values': [] }
      if (classesIx == featureClassesCount - 1)
        break;
    }
    featureClasses[classesIx].values.push(values[valuesIx]);
  }
  for (; valuesIx < valuesCount; valuesIx++) {
    featureClasses[classesIx].values.push(values[valuesIx]);
  }
  featureClasses[classesIx].upper = max;
  return featureClasses;
}

function makeFeatureClassesEqualCount(featureValues, featureClassesCount) {
  let min = featureValues.min;
  let max = featureValues.max;
  let values = Object.keys(featureValues.values).map(key => ({ 'id': key, 'value': featureValues.values[key] })).sort((a, b) => (parseInt(a.value) > parseInt(b.value)) ? 1 : -1);
  const valuesCount = values.length;
  let valuesIx = 0;
  let featureClasses = [];
  let classesIx = 0;
  featureClasses[classesIx] = { 'id': classesIx + 1, 'lower': min, 'values': [] }
  for (; valuesIx < valuesCount; valuesIx++) {
    if ((valuesIx / valuesCount > (classesIx + 1) / featureClassesCount) && (values[valuesIx].value > values[valuesIx - 1].value)) {
      featureClasses[classesIx].upper = values[valuesIx].value;
      classesIx++;
      featureClasses[classesIx] = { 'id': classesIx + 1, 'lower': values[valuesIx].value, 'values': [] }
      if (classesIx == featureClassesCount - 1)
        break;
    }
    featureClasses[classesIx].values.push(values[valuesIx]);
  }
  for (; valuesIx < valuesCount; valuesIx++) {
    featureClasses[classesIx].values.push(values[valuesIx]);
  }
  featureClasses[classesIx].upper = max;
  return featureClasses;
}

// snapshot stuff
// http://google.github.io/palette.js/ Miscellaneous, 17 colors
const palette = [
  "#ff0029",
  "#377eb8",
  "#66a61e",
  "#984ea3",
  "#00d2d5",
  "#ff7f00",
  "#af8d00",
  "#7f80cd",
  "#b3e900",
  "#c42e60",
  "#a65628",
  "#f781bf",
  "#8dd3c7",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
];
const colorForPoint = (p) => palette[parseInt(p.walk_id) % palette.length];

// these should not be re-computed on every render of the App
let totalVisitorsCount = null;
let totalWalksCount = null;

function VisitorActivity() {
  const classes = useStyles();
  const [leftOpen, setLeftOpen] = React.useState(true);
  const [rightOpen, setRightOpen] = React.useState(true);

  // we need this to alert map in MapLayout its size has changed externally
  const mapRef = React.useRef();
  const mapInvalidateSize = () => {
    mapRef.current.leafletElement.invalidateSize();
  };

  const [statistic, setStatistic] = React.useState(() => "snapshot");

  // evolution stuff
  // when selecting an evolution statistic first time, the month slider is selected
  const [evolutionMode, setEvolutionMode] = React.useState(() => "dow");
  const [showEvolutionMean, setShowEvolutionMean] = React.useState(() => false);
  const [evolutionValues, setEvolutionValues] = React.useState(() => ({
    "month": NaN,
    "dow": NaN,
    "hour": NaN,
  }));
  // we use NaN for initial so that we can change it to minimum valid on first view

  const evolutionValue = evolutionValues[evolutionMode];

  const [years, setYears] = React.useState(() => []);
  const [months, setMonths] = React.useState(() => []);
  const [daysOfWeek, setDaysOfWeek] = React.useState(() => []);
  const [hoursOfDay, setHoursOfDay] = React.useState(() => []);

  const [ageClass, setAgeClass] = React.useState(() => []);
  const [sex, setSex] = React.useState(() => []);

  const isFiltered = !!(
    years.length ||
    months.length ||
    daysOfWeek.length ||
    hoursOfDay.length ||
    ageClass.length ||
    sex.length
  );

  const [featureData, setFeatureData] = React.useState(() => []);

  const [pathFeatureType, setPathFeatureType] = React.useState(() => "polygons");

  const [stayMeasurementUnit, setStayMeasurementUnit] = React.useState(() => 'stay-marks');

  const [featureClassification, setFeatureClassification] = React.useState(() => 'equal-count');
  const [featureClassesCount, setFeatureClassesCount] = React.useState(() => 6);

  const [selectedFeatureId, setSelectedFeatureId] = React.useState(() => null);

  const [colorSchemeType, setColorSchemeType] = React.useState(() => 'diverging');

  // geometry data fetch once from backend
  const [initBounds, setInitBounds] = React.useState();
  const [pathFeatures, setPathFeatures] = React.useState(() => ({ buffers: [], polygons: [], lines: [] }));
  React.useEffect(() => {
    Promise.all([
      axios.get('/layers/path_areas/'),
      axios.get('/layers/path_polygons/'),
      axios.get('/layers/path_lines/'),
    ]).then(([
      { data: { bbox, features: buffers } },
      { data: { features: polygons } },
      { data: { features: lines } },
    ]) => {
      setPathFeatures({ buffers, polygons, lines });
      // find the center of buffers bounds and set it
      setInitBounds([[bbox[1], bbox[0]], [bbox[3], bbox[2]]]);
    });

  }, []);

  const [interestFeatures, setInterestFeatures] = React.useState(() => []);
  React.useEffect(() => {
    axios.get('/layers/interest_areas/').then(({ data: { features } }) => {
      setInterestFeatures(features);
    });
  }, []);

  // limit panning to around the area
  const maxBounds = React.useMemo(() => initBounds && L.latLngBounds(initBounds).pad(0.5), [initBounds]);

  const [{
    year: yearData,
    month: monthData,
    dow: dowData,
    hour: hourData,
    age_class: ageData,
    sex: sexData,
    all: generalData,
  }, setChartData] = React.useState(() => ({
    year: [],
    month: [],
    dow: [],
    hour: [],
    age_class: [],
    sex: [],
    all: [],
  }));

  const facetCounts = generalData[0] || {};
  const visitorsCount = facetCounts.applications;
  const visitsCount = facetCounts.visits;
  const walksCount = facetCounts.walks;

  const [statisticType, isEvolution] = statistic.split('-'); 

  // let's memo the filter params to reuse in effects
  const fetchParams = React.useMemo(() => {
    return {
      year: years.join(',') || undefined,
      month: months.join(',') || undefined,
      dow: daysOfWeek.join(',') || undefined,
      hour: hoursOfDay.join(',') || undefined,
      age_class: ageClass.join(',') || undefined,
      sex: sex.join(',') || undefined,
    };
  }, [years, months, daysOfWeek, hoursOfDay, ageClass, sex])

  // fetch filters effect
  React.useEffect(() => {
    // update filter charts also
    axios.get('/facets/', {
      params: fetchParams,
    }).then((res) => {
      const newChartData = res.data;

      // put aside the totals
      if (!isFiltered) {
        totalWalksCount = newChartData.all[0].walks;
        totalVisitorsCount = newChartData.all[0].applications;
      }

      // before updating state, transform the data for months and days of week 
      newChartData.month.forEach((row) => {
        row.monthValue = row.month;
        row.month = monthNames[row.month - 1];
      });
      newChartData.dow.forEach((row) => {
        row.dowValue = row.dow;
        row.dow = dayNames[row.dow - 1];
      });

      setChartData(newChartData);
    });

  }, [fetchParams]);

  // fetch stats effect
  React.useEffect(() => {
    let fetchUrl = '/stats/';
    if (statisticType === 'visits') {
      fetchUrl += 'presence/';
    } else {
      fetchUrl += 'interest/';
    }

    if (isEvolution && evolutionMode) {
      fetchUrl += `${evolutionMode}/`;
    }

    // update map data
    axios.get(fetchUrl, {
      params: fetchParams,
    }).then((res) => {
      const featureData = res.data;
      setFeatureData(featureData);

      if (isEvolution && Number.isNaN(evolutionValues[evolutionMode])) {
        // also set evolution value, if not already set
        const minValidEvolutionValue = Math.min(...featureData.map((v) => v[evolutionMode]).filter(Number.isFinite));
        setEvolutionValues({ ...evolutionValues, [evolutionMode]: minValidEvolutionValue });
      }
    });

  }, [fetchParams, statistic, evolutionMode]);

  const [snapshotPositions, setSnapshotPositions] = React.useState(() => []);
  const [pointColorStyle, setPointColorStyle] = React.useState(() => 'multi');
  const [showPathPolygons, setShowPathPolygons] = React.useState(() => false);

  const handleStatisticSelect = ({target}) => {
    setSelectedFeatureId(null);
    setStatistic(target.value);
  }

  const handlePathFeatureType = (event) => {
    setPathFeatureType(event.target.value);
  };

  const handleYears = (event, newYears) => {
    setYears(newYears);
  };

  const handleMonths = (event, newMonths) => {
    setMonths(newMonths);
  };

  const handleDaysOfWeek = (event, newDaysOfWeek) => {
    setDaysOfWeek(newDaysOfWeek);
  };

  const handleHoursOfDay = (event, newHoursOfDay) => {
    setHoursOfDay(newHoursOfDay);
  };

  const handleAgeClass = (event, newAgeClass) => {
    setAgeClass(newAgeClass);
  };

  const handleSex = (event, newSex) => {
    setSex(newSex);
  };

  const resetAllFilters = () => {
    setYears([]);
    setMonths([]);
    setDaysOfWeek([]);
    setHoursOfDay([]);
    setAgeClass([]);
    setSex([]);
  };

  const handleLeftDrawerOpen = () => {
    setLeftOpen(true);
  };

  const handleLeftDrawerClose = () => {
    setLeftOpen(false);
  };

  const handleRightDrawerOpen = () => {
    setRightOpen(true);
  };

  const handleRightDrawerClose = () => {
    setRightOpen(false);
  };

  const statisticDescription = statisticType === "visits" ? 'Number of traversals for each pathway' + (isEvolution ? ', averaged by time' : '') + '. Data can be filtered using the Filters panel on the left.' : 'Number of stay marks and stay duration for each area' + (isEvolution ? ', averaged by time' : '') + '. Data can be filtered using the Filters panel on the left.';
  const featureValuesUnit = statisticType === 'visits' ? 'traversals' : (stayMeasurementUnit === 'stay-marks' ? 'stay marks' : 'minutes');
  const featureValuesUnitTitle = statisticType === 'visits' ? 'Number of traversals' : (stayMeasurementUnit === 'stay-marks' ? 'Number of stay marks' : 'Stay duration (minutes)');
  const features = statistic === 'snapshot' ? pathFeatures.polygons : (statisticType === "visits" ? pathFeatures[pathFeatureType] : interestFeatures);

  const mapEvolutionValueToName = React.useCallback(mapValueToName.bind(this, evolutionMode), [evolutionMode]);

  const transformedFeatureData = React.useMemo(() => {
    if (statisticType === 'stays' && stayMeasurementUnit === 'stay-duration')
      return featureData.map((datum) => {
        let transformedDatum = {...datum};
        if (transformedDatum.hasOwnProperty('value'))
          transformedDatum['value'] = transformedDatum['value'] / 2;
        else if (transformedDatum.hasOwnProperty('count'))
          transformedDatum['count'] = transformedDatum['count'] / 2;
        return transformedDatum;
      });
    else
      return featureData;
  }, [featureData, statisticType, stayMeasurementUnit]);

  const featureValues = React.useMemo(() => {
    let activeFeatureData = transformedFeatureData;
    if (isEvolution) {
      // we need to pick only the featureValues matching the evolution field value
      activeFeatureData = transformedFeatureData.filter((v) => v[evolutionMode] === (showEvolutionMean ? 'all' : evolutionValue));
    }

    // we also need to compute min/max and transform values to associative array
    return makeFeatureValues(activeFeatureData);
  }, [transformedFeatureData, isEvolution, evolutionMode, evolutionValue, showEvolutionMean]);

  const selectedFeatureValuesEvolution = React.useMemo(() => {
    if (isEvolution && selectedFeatureId) {
      let selectedFeatureData = transformedFeatureData.filter((datum) => datum['area'] === selectedFeatureId);
      return makeSelectedFeatureValuesEvolution(selectedFeatureData, evolutionMode);
    }
    else
      return [];
  }, [transformedFeatureData, isEvolution, evolutionMode, selectedFeatureId]);

  const featureClassesRanges = React.useMemo(() => {
    const sortedFeatureData = [...transformedFeatureData].sort((a, b) => (parseFloat(a.value) > parseFloat(b.value)) ? 1 : -1);
    return featureClassification === 'equal-count' ? makeFeatureClassesRangesEqualCount(sortedFeatureData, featureClassesCount) : makeFeatureClassesRangesEqualInterval(sortedFeatureData, featureClassesCount);
  }, [transformedFeatureData, featureClassification, featureClassesCount]);

  const featureClasses = React.useMemo(() => {
    return makeFeatureClasses(featureValues, featureClassesRanges);
  }, [featureValues, featureClassesRanges]);

  const colorSchemeColors = React.useMemo(() => {
    return colorSchemeType === 'diverging' ? makeDivergingColorSchemeColors(featureClassesCount) : qualitativeColorSchemeColors;
  }, [featureClassesCount, colorSchemeType]);

  const [featureColors, featureClassesColors] = React.useMemo(() => {
    return [makeFeatureColors(featureClasses, colorSchemeColors), makeFeatureClassesColors(featureClasses, colorSchemeColors)];
  }, [featureClasses, colorSchemeColors]);

  const [sortedFeatureValues, sortedFeatureValuesIndex] = React.useMemo(() => {
    let sortedFeatureValues = Object.keys(featureValues.values).map(key => ({ 'id': parseInt(key), 'value': featureValues.values[key] })).sort((a, b) => (parseFloat(a.value) > parseFloat(b.value)) ? 1 : -1);
    let sortedFeatureValuesIndex = {};
    sortedFeatureValues.forEach((fv, ix) => sortedFeatureValuesIndex[fv.id] = ix);

    return [sortedFeatureValues, sortedFeatureValuesIndex];
  }, [featureValues]);

  // we never want left drawer to be open when in snapshot mode
  // but we want to remember if it was before going to snapshot
  const trulyLeftOpen = leftOpen && statistic !== "snapshot";

  // change page title according to deployment
  const pageTitle = 'Park visitor activity' + (isSim ? ' - Artificial data' : '');

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <AppBar
        position='fixed'
        color='transparent'
        className={clsx(classes.appBar, {
          [classes.appBarLeftShift]: trulyLeftOpen && !rightOpen,
          [classes.appBarRightShift]: !trulyLeftOpen && rightOpen,
          [classes.appBarLeftRightShift]: trulyLeftOpen && rightOpen,
        })}
      >
        <Toolbar>
          <Tooltip classes={{tooltip: classes.tooltip}} title={`Show filters${isFiltered ? ' (some are applied)' : ''}`}>
            <IconButton
              color="inherit"
              aria-label="show filters"
              onClick={handleLeftDrawerOpen}
              edge="start"
              className={clsx(classes.leftMenuButton, trulyLeftOpen && classes.hide)}
              disabled={statistic === "snapshot"}
            >
              <TuneIcon color={statistic !== "snapshot" && isFiltered ? 'secondary' : 'inherit'}/>
            </IconButton>
          </Tooltip>

          <Typography variant="h6" noWrap>
            {pageTitle}
          </Typography>

          <div className={classes.grow} />

          <div className={classes.buttons}>
            <Tooltip classes={{tooltip: classes.tooltip}} title={issuesUrl}>
              <Button endIcon={<LaunchIcon />} color="primary" href={issuesPathname}>
                Park issues
              </Button>
            </Tooltip>

            <Divider orientation="vertical" flexItem />
            <Button endIcon={<WalkIcon />} color="primary" component={Link} to="./walk-data">
              Walk Data
            </Button>

            <Divider orientation="vertical" flexItem />

            <AboutDialog />
          </div>


          <Tooltip classes={{tooltip: classes.tooltip}} title="Show details">
            <IconButton
              color="inherit"
              aria-label="show details"
              edge="end"
              onClick={handleRightDrawerOpen}
              className={clsx(classes.rightMenuButton, rightOpen && classes.hide)}
            >
              <EqualizerIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.leftDrawer}
        variant="persistent"
        anchor="left"
        open={trulyLeftOpen}
        classes={{
          paper: classes.leftDrawerPaper,
        }}
        SlideProps={{
          onEntered: mapInvalidateSize,
          onExited: mapInvalidateSize,
        }}
      >
        <Box className={classes.leftDrawerHeader}>
          <Box flexGrow={1} display="flex" justifyContent="flex-start" alignItems="center">
            <Box mr={2}>
              <Typography variant="h6">
                Filters
              </Typography>
            </Box>
            <Button variant="outlined" startIcon={<ReplayIcon />} onClick={resetAllFilters}>Clear all</Button>
          </Box>
          <IconButton onClick={handleLeftDrawerClose} style={{ marginLeft: '8px' }}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>

        <Paper square>
          <Box mx={1} px={1}>
            <table style={{ textAlign: 'right', margin: '0 auto' }}><tbody>
              <tr>
                <td>Walks:</td>
                <th>{walksCount}</th>
                <td>
                  <Tooltip classes={{tooltip: classes.tooltip}} title="The number of walks of any one visitor in the park. If a visitor returns to the park after at least two hours, it is considered a separate walk.">
                    <IconButton size="small" aria-label="details">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </td>
                <td>Total available:</td>
                <th>{totalWalksCount}</th>
              </tr>
              <tr>
                <td>Visitors:</td>
                <th>{visitorsCount}</th>
                <td>
                  <Tooltip classes={{tooltip: classes.tooltip}} title="The number of unique visitors walking in the park">
                    <IconButton size="small" aria-label="details">
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </td>
                <td>Total available:</td>
                <th>{totalVisitorsCount}</th>
              </tr>
            </tbody></table>
          </Box>
        </Paper>

        <Box px={2} py={1} overflow="auto">

          <Typography variant="h6">
            Years
          </Typography>
          <Box height='160px'>
            <MyResponsiveBar data={yearData} keys={['walks']} indexBy={'year'} colors="#26547C" />
          </Box>
          <div className={classes.toggleContainer}>
            <FilterToggleButtonGroup pushAll value={years} onChange={handleYears} aria-label="years filter" size="small"
              options={[
                { value: 2019, text: "2019" },
                { value: 2020, text: "2020" },
                { value: 2021, text: "2021" },
                { value: 2022, text: "2022" },
              ]}
              data={yearData.map(r => r.year)}
            />
          </div>

          <Divider variant="middle" />

          <Typography variant="h6">
            Months
          </Typography>
          <Box height='160px'>
            <MyResponsiveBar data={monthData} keys={['walks']} indexBy={'month'} colors="#EF476F" />
          </Box>
          <div className={classes.toggleContainer}>
            <FilterToggleButtonGroup pushAll value={months} onChange={handleMonths} aria-label="months filter" size="small"
              options={[
                { value:  1, text: "Jan", "aria-label": "January" },
                { value:  2, text: "Feb", "aria-label": "February" },
                { value:  3, text: "Mar", "aria-label": "March" },
                { value:  4, text: "Apr", "aria-label": "April" },
                { value:  5, text: "May", "aria-label": "May" },
                { value:  6, text: "Jun", "aria-label": "June" },
                <br/>,
                { value:  7, text: "Jul", "aria-label": "July" },
                { value:  8, text: "Aug", "aria-label": "August" },
                { value:  9, text: "Sep", "aria-label": "September" },
                { value: 10, text: "Oct", "aria-label": "October" },
                { value: 11, text: "Nov", "aria-label": "November" },
                { value: 12, text: "Dec", "aria-label": "December" },
              ]}
              data={monthData.map(r => r.monthValue)}
            />
          </div>

          <Divider variant="middle" />

          <Typography variant="h6">
            Day of week
          </Typography>
          <Box height='160px'>
            <MyResponsiveBar data={dowData} keys={['walks']} indexBy={'dow'} colors="#FFD166" />
          </Box>
          <div className={classes.toggleContainer}>
            <FilterToggleButtonGroup pushAll value={daysOfWeek} onChange={handleDaysOfWeek} aria-label="days of week filter" size="small"
              options={[
                { "value": 1, "text": "Mon", "aria-label": "monday" },
                { "value": 2, "text": "Tue", "aria-label": "tuesday" },
                { "value": 3, "text": "Wed", "aria-label": "wednesday" },
                { "value": 4, "text": "Thu", "aria-label": "thursday" },
                { "value": 5, "text": "Fri", "aria-label": "friday" },
                { "value": 6, "text": "Sat", "aria-label": "saturday" },
                { "value": 7, "text": "Sun", "aria-label": "sunday" },
              ]}
              data={dowData.map(r => r.dowValue)}
            />
          </div>

          <Divider variant="middle" />

          <Typography variant="h6">
            Hour of day
          </Typography>
          <Box height='160px'>
            <MyResponsiveBar data={hourData} keys={['walks']} indexBy={'hour'} colors="#06D6A0" />
          </Box>
          <div className={classes.toggleContainer}>
            <FilterToggleButtonGroup pushAll value={hoursOfDay} onChange={handleHoursOfDay} aria-label="hours of day filter" size="small"
              options={[
                ...hourIntervals.slice(0, 6).map((text, i) => ({ value: i, text })),
                <br />,
                ...hourIntervals.slice(6, 12).map((text, i) => ({ value: i + 6, text })),
                <br />,
                ...hourIntervals.slice(12, 18).map((text, i) => ({ value: i + 12, text })),
                <br />,
                ...hourIntervals.slice(18, 24).map((text, i) => ({ value: i + 18, text })),
              ]}
              data={hourData.map(r => r.hour)}
            />
          </div>

          <Divider variant="middle" />

          <Typography variant="h6">
            Visitor age
          </Typography>
          <Box height='160px'>
            <MyResponsiveBar data={ageData} keys={['walks']} indexBy={'age_class'} colors="#D1D166" />
          </Box>
          <div className={classes.toggleContainer}>
            <FilterToggleButtonGroup pushAll value={ageClass} onChange={handleAgeClass} aria-label="visitor age class filter" size="small"
              options={[
                { value: "12-24", text: "12-24" },
                { value: "25-44", text: "25-44" },
                { value: "45-64", text: "45-64" },
                { value: "65+" , text: "65+" },
                { value: "NA" , text: "No Answer", className: "fluid" },
              ]}
              data={ageData.map(r => r.age_class)}
            />
          </div>

          <Divider variant="middle" />

          <Typography variant="h6">
            Visitor sex
          </Typography>
          <Box height='160px'>
            <MyResponsiveBar data={sexData} keys={['walks']} indexBy={'sex'} colors="#FFD1D1" />
          </Box>
          <div className={classes.toggleContainer}>
            <FilterToggleButtonGroup pushAll value={sex} onChange={handleSex} aria-label="visitor sex filter" size="small"
              options={[
                { "className": "fluid", value: "F",  text: "Female" },
                { "className": "fluid", value: "M",  text: "Male" },
                { "className": "fluid", value: "NA", text: "No Answer" },
              ]}
              data={sexData.map(r => r.sex)}
            />
          </div>

        </Box>

      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentLeftShift]: trulyLeftOpen,
          [classes.contentRightShift]: rightOpen,
        })}
      >
        <div className={classes.leftDrawerHeader} />

        <div className={classes.map}>
          { initBounds && <MapLayout ref={mapRef} bounds={initBounds} maxBounds={maxBounds} height='100%'>
              { statistic === 'snapshot' ?
                [
                  <SnapshotLayer positions={snapshotPositions} colorForPoint={ pointColorStyle === 'multi' && colorForPoint} />,
                  showPathPolygons ?
                    <SimpleFeatureLayer
                      features={features}
                      fillOpacity={0.1} weight={1} interactive={false}
                    /> :
                    null,
                ]
                :
                <StatisticsLayer 
                  featureType={statisticType === "visits" ? pathFeatureType : statisticType}
                  features={features} featureValues={featureValues}
                  featureColors={featureColors}
                  featureValuesUnit={featureValuesUnit}
                  selectedFeatureId={selectedFeatureId}
                  setSelectedFeatureId={setSelectedFeatureId}
                />
              }
            </MapLayout>
          }
        </div>
      </main>
      <Drawer
        className={classes.rightDrawer}
        variant="persistent"
        anchor="right"
        open={rightOpen}
        classes={{
          paper: classes.rightDrawerPaper,
        }}
        SlideProps={{
          onEntered: mapInvalidateSize,
          onExited: mapInvalidateSize,
        }}
      >
        <div className={classes.rightDrawerHeader}>
          <IconButton onClick={handleRightDrawerClose} style={{marginRight: '8px'}}>
            <ChevronRightIcon />
          </IconButton>
          <FormControl variant='outlined' className={classes.formControl}>
            <Select className={classes.dataSelect} value={statistic} onChange={handleStatisticSelect}>
              <MenuItem value="snapshot">Activity animation</MenuItem>
              <MenuItem value="visits">Pathway popularity</MenuItem>
              <MenuItem value="stays">Area popularity</MenuItem>
              <MenuItem value="visits-evolution">Pathway popularity by time</MenuItem>
              <MenuItem value="stays-evolution">Area popularity by time</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Divider />

        { statistic !== 'snapshot' ? <Box overflow="auto" style={{ padding: '1em' }}>

          <Typography variant="subtitle1">
            {statisticDescription}
          </Typography>

          <Box my={1} textAlign="right">
            <ExportButton 
              featureData={transformedFeatureData} filterParams={fetchParams}
              featureColumnName={featureValuesUnit === 'traversals' ? 'Pathway ID' : 'Area ID'}
              valueColumnName={featureValuesUnitTitle}
              evolutionMode={isEvolution && evolutionMode}
            />
          </Box>

          <Divider style={{ 'margin-top': '0.5em', 'margin-bottom': '0.5em' }} />

          { isEvolution && <React.Fragment>
            <Typography variant="h6" style={{ 'margin-bottom': '0.25em' }}>
              Comparison mode
                <Tooltip classes={{tooltip: classes.tooltip}} title={statisticType === 'visits' ? 'Pathways are compared by time according to the selected time dimension, namely, month, day of week, or hour interval. If "Mean values" is ON, then the value displayed for each pathway is the average number of traversals per month, day, or hour interval. If "Mean values" is OFF, then the slider is used to select a specific month, day of week, or hour interval. In that case, the value displayed for each pathway is the average number of traversals per month, day, or hour interval, with the averaging restricted to the selected month, day of week, or hour interval, respectively.' : 'Areas are compared by time according to the selected time dimension, namely, month, day of week, or hour interval. If "Mean values" is ON, then the value displayed for each area is the average ' + (stayMeasurementUnit === 'stay-marks' ? 'number of stay marks' : 'stay duration') + ' per month, day, or hour interval. If "Mean values" is OFF, then the slider is used to select a specific month, day of week, or hour interval. In that case, the value displayed for each area is the average ' + (stayMeasurementUnit === 'stay-marks' ? 'number of stay marks' : 'stay duration') + ' per month, day, or hour interval, with the averaging restricted to the selected month, day of week, or hour interval, respectively.'}>
                <IconButton size="small" aria-label="info">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>

              <FormControl className={classes.formControl}>
                <InputLabel id="evolution-mode-select-label">
                  Time dimension
                </InputLabel>
                <Select labelId="evolution-mode-select-label" value={evolutionMode} onChange={({target}) => {setEvolutionMode(target.value); setSelectedFeatureId(null);}}>
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="dow">Day of week</MenuItem>
                  <MenuItem value="hour">Hour interval</MenuItem>
                </Select>
              </FormControl>

              <FormControlLabel
                label="Mean values"
                control={
                  <Switch
                    checked={showEvolutionMean}
                    onChange={({target}) => setShowEvolutionMean(target.checked)}
                    color="primary"
                  />
                }
              />

              <Box mt={5} style={{ textAlign: 'center' }}>
                <Slider
                  style={{ width: '90%' }}
                  disabled={showEvolutionMean}
                  value={evolutionValue}
                  onChange={(event, newValue) => setEvolutionValues({ ...evolutionValues, [evolutionMode]: newValue })}
                  step={1}
                  marks={true}
                  min={evolutionMode === 'hour' ? 0 : 1}
                  max={evolutionMode === 'hour' ? 23 : evolutionMode === 'month' ? 12 : 7}
                  valueLabelDisplay={ evolutionValue ? "on" : "off" }
                  valueLabelFormat={(value) => {
                    const sliderMark = evolutionSliderMarks[evolutionMode].find((v) => v.value === value);
                    return sliderMark ? sliderMark.label : '';
                  }}
                  track={false}
                />
              </Box>

              <Box style={{ 'text-align': 'center', 'min-height': '2em' }}>
                {Object.keys(featureValues.values).length === 0 && <em>No data available</em>}
              </Box>

            </React.Fragment>
          }

          <Typography variant="h6" style={{ 'margin-bottom': '0.25em' }}>
            {statisticType === 'visits' ? 'Pathway classification' : 'Area classification'}
            <Tooltip classes={{tooltip: classes.tooltip}} title={statisticType === 'visits' ? 'Pathways are grouped into classes based on the number of traversals for each pathway and the range of each class.' : 'Areas are grouped into classes based on ' + (stayMeasurementUnit === 'stay-marks' ? 'the number of stay marks' : 'stay duration') + ' for each area and the range of each class.'}>
              <IconButton size="small" aria-label="info">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          <FormControl className={classes.formControl} style={{ 'margin-bottom': '1em' }}>
            <InputLabel id="feature-classification-select-label">
              Classification method
              <Tooltip classes={{tooltip: classes.tooltip}} title={statisticType === 'visits' ? 'The ranges of the number of traversals for the pathway classification are defined by the classification method. The equal-count classification method picks ranges so that they contain equal number of pathways (best effort). The equal-interval classification method picks ranges of equal width.' : 'The ranges of ' + (stayMeasurementUnit === 'stay-marks' ? 'the number of stay marks' : 'stay duration') + ' for the area classification are defined by the classification method. The equal-count classification method picks ranges so that they contain equal number of areas (best effort). The equal-interval classification method picks ranges of equal width.'}>
                <IconButton size="small" aria-label="info">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <Select labelId="feature-classification-select-label" value={featureClassification} onChange={({target}) => setFeatureClassification(target.value)}>
              <MenuItem value="equal-count">Equal-count</MenuItem>
              <MenuItem value="equal-interval">Equal-interval</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="subtitle2">
            {featureValuesUnitTitle}
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <GradientLegend featureClasses={featureClasses} colorSchemeColors={colorSchemeColors} featureValuesUnit={featureValuesUnit} />
            </Grid>
            <Grid item xs={6}>
              <Box height='170px'>
                <FeatureClassesPie featureClasses={featureClasses} featureClassesColors={featureClassesColors} featureValuesUnit={featureValuesUnit} />
              </Box>
            </Grid>
          </Grid>

          <Typography variant="h6" style={{ 'margin-bottom': '0.25em' }}>
            {statisticType === 'visits' ? 'Pathway traversals' : 'Area stays'}
            <Tooltip classes={{tooltip: classes.tooltip}} title={statisticType === 'visits' ? 'The bar chart depicts the number of traversals (y-axis) for each pathway (x-axis). The pathways are sorted by the number of traversals. The pink bar marks the selected pathway.' : 'The bar chart depicts ' + (stayMeasurementUnit === 'stay-marks' ? 'the number of stay marks' : 'stay duration') + ' (y-axis) for each area (x-axis). The areas are sorted by ' + (stayMeasurementUnit === 'stay-marks' ? 'the number of stay marks' : 'stay duration') + '. The pink bar marks the selected area.'}>
              <IconButton size="small" aria-label="info">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Typography>

          <Box height='200px'>
            <FeatureValuesBar minValue={0} maxValue={featureClasses.length > 0 ? featureClasses[featureClasses.length - 1].upper : 0} featureValues={featureValues} featureColors={featureColors} sortBy={'value'} keys={['value']} indexBy={'id'} colors="#EF476F" selectedFeatureId={selectedFeatureId} setSelectedFeatureId={setSelectedFeatureId} featureValuesUnit={featureValuesUnit} />
          </Box>

          {Object.keys(featureValues.values).length > 0 && <FeatureValuesNav sortedFeatureValues={sortedFeatureValues} sortedFeatureValuesIndex={sortedFeatureValuesIndex} selectedFeatureId={selectedFeatureId} setSelectedFeatureId={setSelectedFeatureId} featureValuesUnit={featureValuesUnit} />}

          {isEvolution && <React.Fragment>
            <Typography variant="h6" style={{ 'margin-bottom': '0.25em' }}>
              {statisticType === 'visits' ? 'Traversals comparison for selected pathway' : 'Stays comparison for selected area'}
              <Tooltip classes={{tooltip: classes.tooltip}} title={'The bar chart depicts the popularity comparison for the selected ' + (statisticType === 'visits' ? 'pathway' : 'area') + ' by time. The pink bar marks the selected time.'}>
                <IconButton size='small' aria-label='info'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>

            <Box height='200px'>
              {selectedFeatureId ? <SelectedFeatureValuesEvolutionBar selectedFeatureValuesEvolution={selectedFeatureValuesEvolution} sortBy={'id'} keys={['value']} indexBy={'id'} selectedEvolutionValue={showEvolutionMean ? 'all' : evolutionValue} mapEvolutionValueToName={mapEvolutionValueToName} featureValuesUnit={featureValuesUnit} /> : <ResponsiveBar data={[]} minValue={0} maxValue={100} margin={{ top: 20, right: 10, bottom: 40, left: 50 }} />}
            </Box>
          </React.Fragment>}

          <Typography variant="h6" style={{ 'margin-bottom': '0.25em' }}>
            Presentation options
          </Typography>

          <FormControl className={classes.formControl} style={{ 'margin-bottom': '1em' }}>
            <InputLabel id="color-scheme-type-select-label">
              Color scheme
              <Tooltip classes={{tooltip: classes.tooltip}} title={'The colors used for the illustration of ' + (statisticType === 'visits' ? 'pathway' : 'area') + ' classes on the charts and the map. Diverging: The colors form a progression. Multi-color: The colors are disparate.'}>
                <IconButton size='small' aria-label='info'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <Select labelId="color-scheme-type-select-label" value={colorSchemeType} onChange={({target}) => setColorSchemeType(target.value)}>
              <MenuItem value="diverging">Diverging</MenuItem>
              <MenuItem value="multi-color">Multi-color</MenuItem>
            </Select>
          </FormControl>

          { statisticType === "visits" && <React.Fragment>
              <FormControl className={classes.formControl} style={{ 'margin-bottom': '1em' }}>
                <InputLabel id="path-feature-type-label">
                  Pathway representation type
                  <Tooltip classes={{tooltip: classes.tooltip}} title='The shapes used for the illustration of pathways on the map. Polygons: Each pathway is illustrated as the polygon that forms the physical border of the pathway. Lines: Each pathway is illustrated as the line that centrally crosses the pathway. Calculation models: Each pathway is illustrated as a padded polygon encompassing the physical border of the pathway that is also used for pathway popularity calculations.'>
                    <IconButton size='small' aria-label='info'>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <Select
                  labelId="path-feature-type-label"
                  id="path-feature-type"
                  value={pathFeatureType}
                  onChange={handlePathFeatureType}
                >
                  <MenuItem value={'polygons'}>Polygons</MenuItem>
                  <MenuItem value={'lines'}>Lines</MenuItem>
                  <MenuItem value={'buffers'}>Calculation models</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          }

          { statisticType === 'stays' && <React.Fragment>
              <FormControl className={classes.formControl} style={{ 'margin-bottom': '1em' }}>
                <InputLabel id="stay-measurement-unit-label">
                  Stay measurement unit
                  <Tooltip classes={{tooltip: classes.tooltip}} title='The unit of measurement used for depicting area popularity. Number of stay marks: The number of visitor traces that are within the area border. Stay duration: The duration that visitors are within the area border.'>
                    <IconButton size='small' aria-label='info'>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <Select
                  labelId="stay-measurement-unit-label"
                  id="stay-measurement-unit"
                  value={stayMeasurementUnit}
                  onChange={({target}) => setStayMeasurementUnit(target.value)}
                >
                  <MenuItem value={'stay-marks'}>Number of stay marks</MenuItem>
                  <MenuItem value={'stay-duration'}>Stay duration (minutes)</MenuItem>
                </Select>
              </FormControl>
            </React.Fragment>
          }

        </Box> : <VisitorPositionsPanel
          setPositions={setSnapshotPositions}
          pointColorStyle={pointColorStyle}
          setPointColorStyle={setPointColorStyle}
          showPathPolygons={showPathPolygons}
          setShowPathPolygons={setShowPathPolygons}
          classes={classes}
        /> }

      </Drawer>

      <Route path="/walk-data" component={WalkData} />

    </div>
  );
}

export default VisitorActivity;
