import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Link from '@material-ui/core/Link';

import DialogTitle from './DialogTitleWithClose';


const simulationUrl = window.location.origin + window.location.pathname.replace(/(\/.+)\/$/, '$1-sim/');

const WhatAboutTheData = ({ history }) => {

  const handleClose = () => history.push('/');

  return (
    <Dialog open aria-labelledby="what-about-the-data-dialog-title" onClose={handleClose} maxWidth="sm">
      <DialogTitle id="what-about-the-data-dialog-title" onClose={handleClose}>
        Walk data
      </DialogTitle>
      <DialogContent dividers>
        <p>
          This version of the application has been deployed to receive and present actual park data.
        </p>
        <p>
          Another version of the application using artificial data is available at
          {' '}
          <Link href={simulationUrl}>Park visitor activity - Artificial data</Link>.
        </p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhatAboutTheData;
