import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import DialogTitleWithClose from './DialogTitleWithClose';

import image1 from './images/image1.png';
import image2 from './images/image2.png';
import image3 from './images/image3.png';
import image4 from './images/image4.png';
import image5 from './images/image5.png';
import image6 from './images/image6.png';
import image7 from './images/image7.png';


const useStyles = makeStyles(theme => ({
  content: {
    '& img': {
      display: 'block',
      margin: `${theme.spacing(2)}px auto`,
      maxWidth: '80%',
    },
    '& li': {
      marginBottom: theme.spacing(1),
      '& > ul': {
        marginTop: theme.spacing(1),
      },
    },
  },
}));

function WalkDataMaker({ history }) {
  const classes = useStyles();

  const handleClose = () => history.push('/');

  return (
    <Dialog open aria-labelledby="walk-data-maker-dialog-title" maxWidth="md" onClose={handleClose}>
      <DialogTitleWithClose id="walk-data-maker-dialog-title" onClose={handleClose}>
        Walk data
      </DialogTitleWithClose>

      <DialogContent dividers className={classes.content}>
        <p>
          Walk data in &ldquo;Park visitor activity&rdquo; has been automatically generated by an application we developed, called Walk Data Maker.
          </p>

        <p>
          Walk Data Maker is an application that generates simulated walk data. The main purpose of Walk Data Maker is to generate
          realistic walk data that is supplied to the &ldquo;Park visitor activity&rdquo; web application for testing the web application.
          </p>

        <p>Walk Data Maker receives the following parameters:</p>

        <ul>
          <li>
            <strong>Walk count.</strong>{' '}
              The number of walks to generate.
            </li>
          <li>
            <strong>Date and time range for walk start times.</strong>{' '}
              Start times of generated walks will fall within the specified date and time range.
            </li>
          <li>
            <strong>Month weights for walk start times.</strong>{' '}
              The probability that a walk starts on a specific month is proportional to the corresponding weight.
            </li>
          <li>
            <strong>Day of week weights for walk start times.</strong>{' '}
              The probability that a walk starts on a specific day of week is proportional to the corresponding weight.
            </li>
          <li>
            <strong>Hour interval weights for walk start times.</strong>{' '}
              The probability that a walk starts during a specific hour interval is proportional to the corresponding weight.
              Separate hour interval weights are supplied for business days and non-business days.
            </li>
          <li>
            <strong>Polygon (GeoJSON) for the confinement of the walk area.</strong>{' '}
              The polygon for the case of Pedion Areos is the following:
              <br />
            <img alt="Polygon (GeoJSON) for the confinement of the walk area" src={image2} />
          </li>
          <li>
            <strong>Raster (TIFF) for the walk grid used by pathfinding.</strong>{' '}
              The raster for the case of Pedion Areos has 4m&times;4m cells and is the following:
              <img alt="Raster (TIFF) for the walk grid used by pathfinding" src={image7} />
          </li>
          <li>
            <strong>Polygons for walk start areas.</strong>{' '}
              The walk start location will be randomly selected from within the specified polygons.
              The polygons for the case of Pedion Areos represent the entrances/exits of the park and are the following:
              <img alt="Polygons for walk start areas" src={image3} />
          </li>
          <li>
            <strong>Polygons for walk intermediate destination areas.</strong>{' '}
              The walk intermediate destination location will be randomly selected from within the specified polygons.
              The polygons for the case of Pedion Areos represent selected areas of the park and are the following:
              <img alt="Polygons for walk intermediate destination areas" src={image5} />
          </li>
          <li>
            <strong>Polygons for walk final destination areas.</strong>{' '}
              The walk final destination location will be randomly selected from within the specified polygons.
              The polygons for the case of Pedion Areos represent the entrances/exits of the park as presented before.
            </li>
          <li>
            <strong>Walk generation method.</strong>{' '}
              The algorithm used for generating the walks.
              The methods available are the following:
              <ul>
              <li>
                <strong>Random.</strong>{' '}
                  The random generation method attempts to connect the walk start location initially to the walk
                  intermediate destination location and subsequently to the walk final destination location using random
                  movements. Higher probability is given to movements directed towards the destination at the time,
                  rather than to movements not directed towards it, in order to increase the probability of the
                  generation to succeed within a limited number of steps. The following is a walk generated by the
                  random generation method:
                  <img alt="Random walk generation method" src={image1} />
              </li>
              <li>
                <strong>Pathfinding.</strong>{' '}
                  The pathfinding generation method attempts to connect the walk start location initially to the walk
                  intermediate destination location and subsequently to the walk final destination location using
                  pathfinding. The pathfinding generation method employs the walk grid and uses the A* algorithm to find
                  the shortest path to the destination at the time. The following is a walk generated by the pathfinding
                  generation method:
                  <img alt="Pathfinding walk generation method" src={image4} />
              </li>
              <li>
                <strong>Hybrid.</strong>{' '}
                  The hybrid generation method attempts to connect the walk start location initially to the walk
                  intermediate destination location and subsequently to the walk final destination location using both
                  random movements and pathfinding. The hybrid generation method alternates between the random
                  generation mode and the pathfinding generation mode. When in the random generation mode, a random
                  movement is performed. When in the pathfinding generation mode, the walk grid is employed and the A*
                  algorithm is used to find the shortest path to the destination at the time. The path found is then
                  followed for a limited distance. Each time the generation mode at the time completes a step in the
                  generation process, the generation mode for the next step is randomly selected. The following is a
                  walk generated by the hybrid generation method:
                  <img alt="Hybrid walk generation method" src={image6} />
              </li>
            </ul>
          </li>
        </ul>

        <p>
          Pathfinding functionality is provided by <Link href="https://pypi.org/project/pathfinding/">Python pathfinding</Link>.
          </p>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WalkDataMaker;
