import React from 'react';

import {
  LayersControl,
  Map,
  TileLayer,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { BingLayer } from 'react-leaflet-bing';

const { BaseLayer } = LayersControl;

const bingkey = process.env.REACT_APP_BING_MAPS_API_KEY || '';

const MapLayout = React.forwardRef(({
  children, height, ...props
}, ref) => {
  ref = ref || React.useRef();

  // set min/max zoom
  React.useEffect(() => {
    const map = ref.current && ref.current.leafletElement;
    if (!map) return; // next time

    const initZoom = map.getZoom();
    map.setMinZoom(initZoom - 2);
    map.setMaxZoom(initZoom + 2);
  }, [ref]);

  return (
    <Map ref={ref} style={{ height }}
      {...props}
    >
      <LayersControl collapsed position="topright">
        <BaseLayer name='OpenStreetMap' checked>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer name='Bing Maps Roads'>
          <BingLayer bingkey={bingkey} type="Road" />
        </BaseLayer>
        <BaseLayer name='Bing Maps Satelite'>
          <BingLayer bingkey={bingkey} />
        </BaseLayer>
        <BaseLayer name='Bing Maps Satelite with Labels'>
          <BingLayer bingkey={bingkey} type="AerialWithLabels" />
        </BaseLayer>
      </LayersControl>
      { children }
    </Map>
  );
});

export default MapLayout;
