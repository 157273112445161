import React from 'react';

import {
  GeoJSON,
  FeatureGroup,
} from 'react-leaflet';

const SimpleFeatureLayer = ({ features, ...props }) => {

  return (
    <FeatureGroup>
      {
        features.map((feature) =>
          <GeoJSON key={feature.properties.id} data={feature} {...props} />
      )}
    </FeatureGroup>
  );

};

export default SimpleFeatureLayer;
