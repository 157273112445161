import React from 'react';
import { hot } from 'react-hot-loader/root';

import { HashRouter as Router } from 'react-router-dom';

import VisitorActivity from './VisitorActivity';


function App() {
  return (
    <Router>
      <VisitorActivity />
    </Router>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
