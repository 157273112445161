import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

function navigate(sortedFeatureValues, sortedFeatureValuesIndex, selectedFeatureId, setSelectedFeatureId, what) {
  let newIx;
  if (what == 'prev') {
    newIx = sortedFeatureValuesIndex[selectedFeatureId] - 1;
    if (newIx < 0)
      newIx = sortedFeatureValues.length - 1;
  }
  else {
    newIx = sortedFeatureValuesIndex[selectedFeatureId] + 1;
    if (newIx > sortedFeatureValues.length - 1)
      newIx = 0;
  }
  setSelectedFeatureId(sortedFeatureValues[newIx].id);
}

export const FeatureValuesNav = ({ sortedFeatureValues, sortedFeatureValuesIndex, selectedFeatureId, setSelectedFeatureId, featureValuesUnit }) => {
  if (selectedFeatureId && sortedFeatureValuesIndex.hasOwnProperty(selectedFeatureId) && sortedFeatureValues.hasOwnProperty(sortedFeatureValuesIndex[selectedFeatureId])) {
    return (
      <Grid container style={{ 'min-height': '64px' }} spacing={1} alignItems='center'>
        <Grid item xs={2} style={{ 'text-align': 'center' }}>
          <IconButton onClick={() => navigate(sortedFeatureValues, sortedFeatureValuesIndex, selectedFeatureId, setSelectedFeatureId, 'prev')}>
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8} style={{ 'text-align': 'center', 'color': '#ff00ff' }}>
          {featureValuesUnit === 'traversals' ? 'Pathway' : 'Area'} {sortedFeatureValues[sortedFeatureValuesIndex[selectedFeatureId]].id}: {sortedFeatureValues[sortedFeatureValuesIndex[selectedFeatureId]].value.toFixed(2)} {featureValuesUnit}
        </Grid>
        <Grid item xs={2} style={{ 'text-align': 'center' }}>
          <IconButton onClick={() => navigate(sortedFeatureValues, sortedFeatureValuesIndex, selectedFeatureId, setSelectedFeatureId, 'next')}>
            <ChevronRightIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
  else {
    return (
      <Grid container style={{ 'min-height': '64px' }} spacing={1} alignItems='center'>
        <Grid item xs={12} style={{ 'text-align': 'center' }}>
          <em>{featureValuesUnit === 'traversals' ? 'Click a bar on the chart to select a pathway' : 'Click a bar on the chart to select an area'}</em>
        </Grid>
      </Grid>
    );
  }
};
