import React from 'react';
import { ResponsivePie } from '@nivo/pie';

function makeData(featureClasses) {
  if (featureClasses) {
    return featureClasses.map(elem => ({ 'id': elem.id, 'value': elem.values.length, 'label': (elem.lower.toFixed(2)).toString() + '-' + (elem.upper.toFixed(2)).toString() }));
  }
  else
    return [];
}

// https://nivo.rocks/pie/
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const FeatureClassesPie = ({ featureClasses, featureClassesColors, featureValuesUnit }) => (
  <ResponsivePie
    data={makeData(featureClasses)}
    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    colors={function getColor(datum) { return featureClassesColors[datum.id]; }}
    borderWidth={1}
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    tooltip={({ value, label, color }) => (
      <span>
        {label} {featureValuesUnit}: {value} {featureValuesUnit === 'traversals' ? 'pathways' : 'areas'}
      </span>
    )}
    enableRadialLabels={false}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);