import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InfoIcon from '@material-ui/icons/Info';
import Link from '@material-ui/core/Link';

import DialogTitleWithClose from './DialogTitleWithClose';


const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const AboutDialog = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button color="primary" onClick={handleClickOpen} endIcon={<InfoIcon />}>
        About
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="about-dialog-title" open={open}>
        <DialogTitleWithClose id="about-dialog-title" onClose={handleClose} className={classes.title}>
          About "Park visitor activity"
        </DialogTitleWithClose>
        <DialogContent style={{ 'text-align': 'center' }} dividers>
          <Link href="https://vr-park.org/" display="block">
            <img src={`${process.env.PUBLIC_URL}/website_VR-Park-logo-1.png`} alt="VR-Park logo" />
          </Link>
          <Link href="https://vr-park.org/" variant="h6" style={{ 'margin-bottom': '0.25em' }} display="block">
            https://vr-park.org/
          </Link>
          <img src={`${process.env.PUBLIC_URL}/ESPA-logo-GR.jpg`} alt="ESPA logo" style={{ 'max-width': '100%' }} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AboutDialog;
