import React from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import NextIcon from '@material-ui/icons/SkipNext';
import PreviousIcon from '@material-ui/icons/SkipPrevious';
import InfoIcon from '@material-ui/icons/Info';

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { addMinutes, subMinutes } from 'date-fns'

import axios from 'axios';


const dateFns = new DateFnsUtils();

const VisitorPositionsPanel = ({
  setPositions,
  pointColorStyle, setPointColorStyle,
  showPathPolygons, setShowPathPolygons,
  classes
}) => {
  const [selectedDate, setSelectedDate] = React.useState(() => null);
  const [dateRange, setDateRange] = React.useState(() => ({}));

  const [requestedDate, setRequestedDate] = React.useState(() => selectedDate);
  const [timeStep, setTimeStep] = React.useState(() => 5);

  React.useEffect(() => {
    // on mount, set selected date
    axios.get('/positions/stats/').then(({ data }) => {
      if (!data.max_timestamp || !data.min_timestamp) {
        // empty database, do nothing
        return;
      }

      const earliest = new Date(data.min_timestamp);
      const latest = new Date(data.max_timestamp);
      const newSelected = dateFns.mergeDateAndTime(latest, dateFns.parse("12:00", dateFns.time24hFormat));

      setRequestedDate(newSelected);
      setDateRange({earliest, latest});
    });

  }, []);

  const snapshotParams = React.useMemo(() => ({
    start_time: requestedDate
  }), [requestedDate]);

  const [isPlaying, setIsPlaying] = React.useState(() => false);
  const stepForwards = () => setRequestedDate((d) => addMinutes(d, timeStep));
  const stepBackwards = () => setRequestedDate((d) => subMinutes(d, timeStep));

  const handleChangeDate = (newValue) => {
    if (!isPlaying) {
      setRequestedDate(newValue);
    }
  }

  const handlePlayPause = () => {
    setIsPlaying((prev) => {
      if (!prev) {
        // start playing 
        stepForwards();
      }

      return !prev;
    });
  }

  const handlePrevious = () => {
    stepBackwards();
  };

  const handleNext = () => {
    stepForwards();
  };

  // fetch snapshot
  React.useEffect(() => {
    let fetchUrl = '/positions/';

    axios.get(fetchUrl, {
      params: snapshotParams
    }).then((res) => {
      setPositions(res.data);
      setSelectedDate(requestedDate);

      if (isPlaying) {
        // re-request half a minute after getting last result
        setTimeout(stepForwards, 500);
      }

    }).catch((err) => {
      // do nothing
    });

  }, [snapshotParams, isPlaying]);

  const handleTimeInputChange = ({target: {value}}) => {
    setRequestedDate((d) => dateFns.mergeDateAndTime(d, dateFns.parse(value, dateFns.time24hFormat)));
  };

  const handleTimeStepChange = ({target: {value}}) => {
    setTimeStep(value);
  }

  const handlePointColorChange = ({target: {value}}) => {
    // either single or multi
    setPointColorStyle(value);
  }

  const handleShowPathPolygonsChange = ({target: {checked}}) => {
    setShowPathPolygons(checked);
  }

  return (
    <Box overflow="auto" style={{ padding: '1em' }}>
      <Typography variant="subtitle1">
        Animation of visitor traces by time
      </Typography>

      <Divider style={{ 'margin-top': '0.5em', 'margin-bottom': '0.5em' }} />
      <Typography variant="h6">
        Animation options
        <Tooltip classes={{tooltip: classes.tooltip}} title='The date, the time, and the step interval between consecutive frames can be set.'>
          <IconButton size='small' aria-label='info'>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Typography>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker variant="static"
          disableToolbar={true}
          disabled={isPlaying}
          value={selectedDate} onChange={handleChangeDate}
          minDate={dateRange && dateRange.earliest}
          maxDate={dateRange && dateRange.latest}
        />
      </MuiPickersUtilsProvider>

      <Grid container spacing={3} style={{ marginBottom: '1em' }}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="current-time">
              Current time
            </InputLabel>
            <Input id="current-time" type="time" required
              value={selectedDate && dateFns.format(selectedDate, dateFns.time24hFormat)}
              onChange={handleTimeInputChange}
              readOnly={isPlaying}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl disabled={isPlaying} fullWidth> 
            <InputLabel htmlFor="step-interval">
              Step interval
            </InputLabel>
  
            <Select id="step-interval" value={timeStep} onChange={handleTimeStepChange}>
              <MenuItem value={1}>1 min</MenuItem>
              <MenuItem value={5}>5 min</MenuItem>
              <MenuItem value={10}>10 min</MenuItem>
              <MenuItem value={15}>15 min</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Typography variant="h6">
        Animation controls
      </Typography>

      <Box mb={2} mx="auto" textAlign="center">
        <Tooltip classes={{tooltip: classes.tooltip}} title="Step back in time">
          <IconButton disabled={isPlaying}
            color="inherit"
            onClick={handlePrevious}
          >
            <PreviousIcon />
          </IconButton>
        </Tooltip>

        <Tooltip classes={{tooltip: classes.tooltip}} title={isPlaying ? 'Pause playback' : 'Start playback'}>
          <IconButton
            color="inherit"
            onClick={handlePlayPause}
          >
            { isPlaying ? <PauseIcon /> : <PlayIcon /> }
          </IconButton>
        </Tooltip>

        <Tooltip classes={{tooltip: classes.tooltip}} title="Step forward in time">
          <IconButton disabled={isPlaying}
            color="inherit"
            onClick={handleNext}
          >
            <NextIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Typography variant="h6" style={{ 'margin-bottom': '0.25em' }}>
        Presentation options
      </Typography>

      <FormControl style={{ width: '100%', 'margin-bottom': '1em' }}>
        <InputLabel id="snapshot-color-theme">
          Color scheme
          <Tooltip classes={{tooltip: classes.tooltip}} title={'The colors used for the illustration of visitor traces on the map. Single-color: One color is used for all visitors. Multi-color: Different colors are used to distinguish between different visitors.'}>
            <IconButton size='small' aria-label='info'>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </InputLabel>
        <Select
          labelId="snapshot-color-theme"
          value={pointColorStyle}
          onChange={handlePointColorChange}
        >
          <MenuItem value="multi">Multi-color</MenuItem>
          <MenuItem value="single">Single-color</MenuItem>
        </Select>
      </FormControl>

      <FormControlLabel style={{ width: '100%', 'margin-bottom': '1em' }}
        control={
          <Checkbox
            labelId="snapshot-show-polygons"
            checked={showPathPolygons}
            onChange={handleShowPathPolygonsChange}
            color="primary"
          />
        }
        label="Show pathway polygons"
      />

    </Box>
  );
};

export default VisitorPositionsPanel;
