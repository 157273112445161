import React from 'react';

import {
  CircleMarker,
  FeatureGroup,
} from 'react-leaflet';

const SnapshotLayer = ({ positions, colorForPoint }) => {
  return (
    <FeatureGroup>
      { positions.map((p) => 
          <CircleMarker key={p.id} interactive={false} center={p} radius={5} color={colorForPoint ? colorForPoint(p) : 'magenta' } />
        ) }
    </FeatureGroup>
  );
};

export default SnapshotLayer;
