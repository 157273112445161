import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

function makeData(featureValues, sortBy) {
  let values = featureValues.values;
  if (values) {
    let data = Object.keys(values).map(key => ({ 'id': key, 'value': values[key] }));
    data.sort((a, b) => (parseFloat(a[sortBy]) > parseFloat(b[sortBy])) ? 1 : -1);
    return data;
  }
  else
    return [];
}

// https://nivo.rocks/bar/
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const FeatureValuesBar = ({ minValue, maxValue, featureValues, sortBy, keys, indexBy, featureColors, selectedFeatureId, setSelectedFeatureId, featureValuesUnit }) => (
  <ResponsiveBar
    data={makeData(featureValues, sortBy)}
    minValue={minValue}
    maxValue={maxValue}
    keys={keys}
    indexBy={indexBy}
    layout={'vertical'}
    margin={{ top: 20, right: 10, bottom: 20, left: 50 }}
    padding={0}
    colors={function getColor(datum) { return selectedFeatureId == parseInt(datum.data.id) ? '#ff00ff' : featureColors[datum.data.id]; }}
    defs={[]}
    fill={[]}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    borderWidth={0.1}
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
    }}
    tooltip={({ value, indexValue, color }) => (
      <span>
        {featureValuesUnit === 'traversals' ? 'Pathway' : 'Area'} {indexValue}: {value.toFixed(2)} {featureValuesUnit}
      </span>
    )}
    onClick={(node, event) => { setSelectedFeatureId(parseInt(node.data.id)); }}
    enableLabel={false}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    legends={[]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);
