import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Divider from '@material-ui/core/Divider';

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: 'inline',
    margin: 0,
  },
  grouped: {
    border: 'none',
    // borderWidth: 1,
    boxSizing: 'content-box',
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: theme.spacing(0, 1),
    borderRadius: 0,
    whiteSpace: 'nowrap',
    // '&:not(:first-child)': {
    //   borderRadius: theme.shape.borderRadius,
    // },
    // '&:first-child': {
    //   borderRadius: theme.shape.borderRadius,
    // },
    '&.fluid': {
      width: 'auto',
    },
  },
}))(ToggleButtonGroup);

function FilterToggleButtonGroup({options, data, pushAll, ...props}) {
  options = options || []

  let footer = null;
  if (pushAll) {
    // options may also include React elements, so we exclude them from allOptions array computation
    const allOptions = options.filter(o => !React.isValidElement(o)).map(o => o.value);
    const onPushAll = () => props.onChange(null, allOptions);
    const onClearAll = () => props.onChange(null, []);

    footer = (
      <ButtonGroup size="small">
        <Button onClick={onPushAll}>Push all</Button>
        <Button onClick={onClearAll}>Clear all</Button>
      </ButtonGroup>
    );
  }

  return (
    <React.Fragment>
      <StyledToggleButtonGroup {...props}>
        {
          options.map((opt) => {
            if (React.isValidElement(opt)) return opt;
            const {value, text, ...otherProps} = opt;
            return (
              <ToggleButton {...otherProps} value={value} disabled={data && !data.includes(value)} >
                {text}
              </ToggleButton>
            );
          })
        }
      </StyledToggleButtonGroup>
      <br />
      { footer }
    </React.Fragment>
  );
}

export default FilterToggleButtonGroup;
